import React, {useState} from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import {styles} from './styles';

const TechnicalPrograms = () => {
  const [expanded,setExpanded] = useState(null);
  const handleChange = panel => (event, expanded) => {
    setExpanded( expanded ? panel : false);
  }
  return (
    <React.Fragment>
      <h2 className='bannerTitle'>Technical Programs</h2>
      <Card className='cards'>
        <CardMedia
          className='card-banners'
          image="/images/technical-programs.jpg"
          title="Technical Programs"
        />
        <CardContent>
          <Typography className='card-title' component="p">
          Orbita Football has specialized in full mind, body and soul development to compete at the highest level of the game.<br /><br />
          We are experts at what we do and run in our facility all of our technical service programs. Our programs over the last 12 years have consistently provided the right training across the board, we know what you need, when you need and why you need it.<br /><br />
          It's our passion to educate and our duty to create the right environment at an affordable price.<br /><br />
          To sing up to for one of our programs please email <a className='hyperlink' href="mailto:Info@orbitafootball.com">Info@orbitafootball.com</a> <br /><br />
          If you are here and looking for our SDP Summer Program Information and have never been in the program or train with us before, please contact Andrew Quintana at <a className='hyperlink' href="mailto:Info@orbitafootball.com">Info@orbitafootball.com</a> 
          </Typography>

          <Typography className='card-section-title' component="h1">
            In-Season Development
          </Typography>

          <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={styles.heading}>Pre-Academy Clinics</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className='card-txt'>
                Orbita Football Youth Clinics are designed to introduce players of any skill level to the beautiful game of football. We strive to develop the basic skills and attributes necessary to carry the youth player to the next level. Our comprehensive program design emphasizes self sufficiency and ball mastery within an exciting learning environment on and off the field. The curriculum of our clinics exposes players to the world wide culture of football and how it pertains to their overall development as an athlete. After completion of the 16 week clinic program, players will be able to exhibit a greater understanding of fundamental technique, coordination, balance and the culture of football. <br /><br />
                Structure: 16 week program consisting of training twice a week. The week will end with open play, or a scheduled league game. There will be one in-house tournament at the end of the season.<br />
                Ages: 4-7 years old 
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={styles.heading}>Small Group Training</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className='card-txt'>
                Here at Orbita, our players have the opportunity to train and develop the skills/attributes necessary for proper individual development. Each training session will focus on developing a player's individual attributes, on and off the ball, in a small group setting. Training sessions will last appx 90 minutes, within a two-hour window to allow for proper warm-up and cool down.  Orbita's Small Group Training topics range from technique, ball mastery, creativity, speed, agility, and coordination with and without the ball, as well as injury prevention, balance, strengthening, and mental awareness. As Orbita Football trainers, we aim in creating a complete player in all aspects of football, on and off the field, while exposing them to an everyday professional environment.<br /><br />
                Training Schedule: Monday - Friday, where you are able to choose which day(s) you'd like to train according to your schedule. All sessions are completed at your own pace. If you miss a session you have the ability to make it up at a later date without being penalized.  
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={styles.heading}>Training Times</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className='card-txt'>
                3:30pm – 5pm (This session is specifically designed for high school players)<br />
                5:30pm – 7pm (U9 – U14) 
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={styles.heading}>Training Rates</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className='card-txt'>
                12 Sessions Pre-Pay: $35 per session. Total: $420  <br />
                8 Sessions Pre-Pay: $40 per session. Total: $320 <br />
                4 Sessions Pre-Pay: $45 per session. Total: $180 <br />
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default TechnicalPrograms