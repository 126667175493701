import React, { Component } from 'react';
import './App.css';

import Web from './components/Web/Web';

const App = () => (
  <div className="App">
    <Web />
  </div>
)

export default App;
