import React, {useState} from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import {styles} from './styles';

const Academy = () => {
  const [expanded,setExpanded] = useState(null);
  const handleChange = panel => (event, expanded) => {
    setExpanded( expanded ? panel : false);
  }
  return (
    <React.Fragment>
      <h2 className='bannerTitle'>Academy</h2>
      <Card className='cards'>
        <CardMedia
          className='card-banners'
          image="/images/academy-banner.jpg"
          title="Academy"
        />
        <CardContent>
          <Typography className='card-title' component="p">
          Orbita Football Academy is a combination of all of our experience in personal development, facility services and competitive football mix into one.<br /><br />
          Trials for 2019 - 2020 season will be posted on our Facebook page.<br /><br />
          Pease read through the Academy sections for more information.<br /><br />
          Orbita Football Development services, structure, expertise and a balance holistic personal approach is all included with your academy membership!<br /><br />
          </Typography>

          <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={styles.heading}>General Information</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className='card-txt'>
              Orbita Football Academy will stand to:<br /><br />
              <ul>
                <li>• Uphold the respect the game deserves and instill the passion needed to love our sport.</li>
                <li>• We will always maintain our vision of equality and balance as people first, then player and finally team.</li>
                <li>• We want to evolve with the game and aid to the development of a fair economic environment. We have devoted our lives to this sport and together we will work to make sure we are adding to the positive development of football worldwide.</li>
                <li>• Our Academy will compete as a US Clubs Soccer affiliate "Academy" with the goal of providing you a complete development structure for serious players.</li>
              </ul>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={styles.heading}>Academy Structure</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className='card-txt'>
                <ul>
                  <li>• Individual Player Development</li>
                  <li>• Team Training and Orbita Development</li>
                  <li>• Team Competition, Season and Tournaments</li>
                  <li>• Special Discount for all other Orbita Services and Events</li>
                  <li>• Facility and Family Services</li>
                  <li>• Long Term Personal Career Advisory</li>
                </ul>   
                All Included with your monthly membership!
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={styles.heading}>Club Structure</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className='card-txt'>
                <ul>
                  <li>• Pre-Academy (2012 and under) </li>
                  <li>• Youth clinics will serve as a developmental platform for our youth competitive teams. </li>
                  <li>• Academy (2010 & 2011)</li>
                  <li>• Juniors - (2007 - 2009)</li>
                  <li>• Premier - (2005 & 2006)</li>
                  <li>• Select - (2002 - 2004)</li>
                </ul>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={styles.heading}>Clubs Coaches and Staff</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className='card-txt'>
              Our club structure will be able to cover the logistics of every team through our staff; therefore, we will not require a team manager per team.<br /><br />
              All of Orbita Football trainers and partners are available for Academy and our "core" staff is a full time training staff; this means the education provided allows players and coaches to truly evolve together.<br /><br />
              Every team will be under the supervision of our Health & Fitness Director, Kaitlyn Briggs.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={styles.heading}>Academy Pricing</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className='card-txt'>
              Our pricing structure is a result of 12 years of research and the understanding of family economics and needs.<br /><br />
              We want to be fair, comprehensive and affordable.<br /><br />
              For Detail information about next years fees and structure please email us at <a className='hyperlink' href="mailto:Info@orbitafootball.com">Info@orbitafootball.com</a>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default Academy