import React from 'react'

import scrollToComponent from 'react-scroll-to-component';
import './web.css';
import Academy from '../Section/Academy';
import TechnicalPrograms from '../Section/TechnicalPrograms';
import Navigation from './Navigation/Navigation';

class Web extends React.Component {

  scrollToSection = (sec) => {
    let offset = 0;
    switch(sec) {
      case 'Academy':
        offset = -64;
      break;
      default:
      break;
    }
    scrollToComponent(this[sec], { offset, align: 'top', duration: 1500})
  }

  render() {
    return(
      <div className='oBody'>

        <Navigation scrollToSection={(sec) => this.scrollToSection(sec)} />
        
        <section id="banner" ref={(section) => { this.Academy = section; }}>
          <Academy />
        </section>

        <section id="banner" ref={(section) => { this.TechnicalPrograms = section; }}>
          <TechnicalPrograms />
        </section>

        <section id="footer">
            <ul className="icons">
              <li>
                <form action="https://www.facebook.com/orbitafootballllc/" target="_blank">
                  <a className='hyperlink-blue' href="mailto:Info@orbitafootball.com"><i class="material-icons">email</i></a>
                </form>
                <form action="https://www.facebook.com/orbitafootballllc/" target="_blank">
                  <button type='submit' className="icon fa-facebook fbook-btn"><span className="label">Facebook</span></button>
                </form>
              </li>
            </ul>
        </section>
      </div>
    )
  }
}

export default Web;