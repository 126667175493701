import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class Navigation extends React.Component {

  state = {
    auth: true,
    anchorEl: null,
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { scrollToSection } = this.props;
    return (
      <div className={styles.root}>
        <AppBar className='app-bar' color='default' position="fixed">
          <Toolbar className='app-bar-wrap'>
            <div className='orbita-logo' />
            <Button className='margin-right-10 nav-btn' onClick={() => scrollToSection('Academy')} 
              variant="contained">
              Academy
            </Button>
            <Button className='nav-btn' onClick={() => scrollToSection('TechnicalPrograms')} 
              variant="contained">
              Technical Programs
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default Navigation;